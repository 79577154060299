import { createEnv, NODE_ENV } from '@ion/env';
import { z } from 'zod';

const env = createEnv({
  server: {
    NODE_ENV: NODE_ENV,
    GOOGLE_ID: z.string().min(1),
    GOOGLE_SECRET: z.string().min(1),
    GITHUB_ID: z.string().min(1),
    GITHUB_SECRET: z.string().min(1),
    NEXTAUTH_SECRET: process.env.NODE_ENV === 'production' ? z.string().min(1) : z.string().min(1).optional(),
    FIGMA_CLIENT_SECRET: z.string().min(1),
    NEW_GITHUB_ACCESS_TOKEN: z.string().min(1),
    GITHUB_PERSONAL_ACCESS_TOKEN: z.string().min(1),
    ANTHROPIC_API_KEY: z.string().min(1),
    TEST_USER_EMAIL: z.string().email(),
    DISABLE_ION_COMPONENTS: z.preprocess((str) => str === 'true', z.boolean().optional().default(false)),
    AWS_S3_BUCKET_NAME: z.string().min(1),
    AWS_S3_ACCESS_KEY_ID: z.string().min(1),
    AWS_S3_SECRET_ACCESS_KEY: z.string().min(1),
    OPENAI_API_KEY: z.string().min(1),
    OPENAI_ORGANIZATION: z.string().min(1),
    GOOGLE_FONT_API_KEY: z.string().min(1),
    FLY_IO_API_KEY: z.string().min(1),
    CONDUCTOR_API_KEY: z.string().min(1),
    CONDUCTOR_SERVER_API_KEY: z.string().min(1),
    DOCKER_KEY: z.string().min(1),
  },
  client: {
    NEXT_PUBLIC_POSTHOG_KEY: z.string().min(1),
    NEXT_PUBLIC_POSTHOG_HOST: z.string(),
    NEXT_PUBLIC_FIGMA_CLIENT_ID: z.string().min(1),
    NEXT_PUBLIC_SENTRY_DSN: z.string().min(1),
    NEXT_PUBLIC_DITTOFEED_WRITE_KEY: z.string().min(1),
    NEXT_PUBLIC_BASE_URL: z.preprocess(
      // This makes Vercel deployments not fail if you don't set NEXTAUTH_URL
      // Since NextAuth.js automatically uses the VERCEL_URL if present.
      (str) => str ?? process.env.NEXT_PUBLIC_VERCEL_URL,
      // VERCEL_URL doesn't include `https` so it cant be validated as a URL
      process.env.NEXT_PUBLIC_VERCEL_URL ? z.string().min(1) : z.string().url()
    ),
  },
  runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    GOOGLE_ID: process.env.GOOGLE_ID,
    GOOGLE_SECRET: process.env.GOOGLE_SECRET,
    GITHUB_ID: process.env.GITHUB_ID,
    GITHUB_SECRET: process.env.GITHUB_SECRET,
    NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
    NEXT_PUBLIC_DITTOFEED_WRITE_KEY: process.env.NEXT_PUBLIC_DITTOFEED_WRITE_KEY,
    NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
    NEXT_PUBLIC_FIGMA_CLIENT_ID: process.env.NEXT_PUBLIC_FIGMA_CLIENT_ID,
    FIGMA_CLIENT_SECRET: process.env.FIGMA_CLIENT_SECRET,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
    NEW_GITHUB_ACCESS_TOKEN: process.env.NEW_GITHUB_ACCESS_TOKEN,
    GITHUB_PERSONAL_ACCESS_TOKEN: process.env.GITHUB_PERSONAL_ACCESS_TOKEN,
    TEST_USER_EMAIL: process.env.TEST_USER_EMAIL,
    ANTHROPIC_API_KEY: process.env.ANTHROPIC_API_KEY,
    DISABLE_ION_COMPONENTS: process.env.DISABLE_ION_COMPONENTS,
    AWS_S3_BUCKET_NAME: process.env.AWS_S3_BUCKET_NAME,
    AWS_S3_ACCESS_KEY_ID: process.env.AWS_S3_ACCESS_KEY_ID,
    AWS_S3_SECRET_ACCESS_KEY: process.env.AWS_S3_SECRET_ACCESS_KEY,
    OPENAI_API_KEY: process.env.OPENAI_API_KEY,
    OPENAI_ORGANIZATION: process.env.OPENAI_ORGANIZATION,
    GOOGLE_FONT_API_KEY: process.env.GOOGLE_FONT_API_KEY,
    FLY_IO_API_KEY: process.env.FLY_IO_API_KEY,
    CONDUCTOR_API_KEY: process.env.CONDUCTOR_API_KEY,
    CONDUCTOR_SERVER_API_KEY: process.env.CONDUCTOR_SERVER_API_KEY,
    DOCKER_KEY: process.env.DOCKER_KEY,
  },
});

export { env };
