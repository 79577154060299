import Head from 'next/head';
import { match } from 'ts-pattern';

import { cn } from '@/lib/utils';

import Auth from './auth/auth';

interface Props {
  auth?: boolean;
  children: React.ReactNode[] | React.ReactNode;

  className?: string;
}

export default function Layout({ children, auth = false, className }: Props) {
  return (
    <>
      <Head>
        <title>ion design</title>
        <meta name="description" content="How engineers implement design better" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main
        id="main"
        className={cn(
          'max-w-screen bg-background text-foreground relative flex h-0 min-h-screen w-full flex-col',
          className
        )}
      >
        {match(auth)
          .with(true, () => <Auth>{children}</Auth>)
          .otherwise(() => (
            <>{children}</>
          ))}
      </main>
    </>
  );
}
