import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

const NODE_ENV = z.enum(['development', 'preview', 'production', 'test']).default('development');

const ION_AVATAR_IDENTIFIER = 'ion/Avatar';
const ION_AVATAR_LABEL_IDENTIFIER = 'ion/AvatarLabel';
const ION_AUTOCOMPLETE_IDENTIFIER = 'ion/Autocomplete';
const ION_BADGE_IDENTIFIER = 'ion/Badge';
const ION_BANNER_IDENTIFIER = 'ion/Banner';
const ION_BUTTON_IDENTIFIER = 'ion/Button';
const ION_LINK_BUTTON_IDENTIFIER = 'ion/LinkButton';
const ION_CARD_IDENTIFIER = 'ion/Card';
const ION_CHECKBOX_IDENTIFIER = 'ion/Checkbox';
const ION_DIVIDER_IDENTIFIER = 'ion/Divider';
const ION_DRAWER_IDENTIFIER = 'ion/Drawer';
const ION_HINT_IDENTIFIER = 'ion/Hint';
const ION_ICON_BUTTON_IDENTIFIER = 'ion/IconButton';
const ION_INPUT_IDENTIFIER = 'ion/Input';
const ION_INPUTOTP_IDENTIFIER = 'ion/InputOTP';
const ION_LABEL_IDENTIFIER = 'ion/Label';
const ION_MODAL_IDENTIFIER = 'ion/Modal';
const ION_PROGRESS_IDENTIFIER = 'ion/Progress';
const ION_RADIO_IDENTIFIER = 'ion/Radio';
const ION_SELECT_IDENTIFIER = 'ion/Select';
const ION_SELECT_MENU_IDENTIFIER = 'ion/SelectMenu';
const ION_MULTISELECT_IDENTIFIER = 'ion/MultiSelect';
const ION_NAVLINK = 'ion/NavLink';
const ION_SIMPLE_TABS_IDENTIFIER = 'ion/TabsSimple';
const ION_SLIDER_IDENTIFIER = 'ion/Slider';
const ION_SWITCH_IDENTIFIER = 'ion/Switch';
const ION_TABLE_IDENTIFIER = 'ion/TableContainer';
const ION_TABS_IDENTIFIER = 'ion/Tabs';
const ION_TAG_IDENTIFIER = 'ion/Tag';
const ION_TEXTAREA_IDENTIFIER = 'ion/Textarea';
const ION_SIDEBAR_IDENTIFIER = 'ion/Sidebar';
const ION_SIDE_NAVIGATION_DEFAULT_IDENTIFIER = 'ion/SideNavigation/default';
const ION_SIDE_NAVIGATION_FILLED_IDENTIFIER = 'ion/SideNavigation/filled';
const ION_SIDE_NAVIGATION_ITEM_IDENTIFIER = 'ion/SideNavigationItem';
const ION_SIDE_NAVIGATION_ITEM_FILLED_IDENTIFIER = 'ion/FilledSideNavigationItem';
const ION_FILLED_ICON_IDENTIFIER = 'ion/FilledIcon';

const ION_COLLAPSIBLE_IDENTIFIER = 'ion/Collapsible';
const ION_PAGINATION_IDENTIFIER = 'ion/Pagination';
const ION_POPOVER_IDENTIFIER = 'ion/Popover';
const ION_DATEPICKER_IDENTIFIER = 'ion/DatePicker';
const ION_CALENDAR_IDENTIFIER = 'ion/DatePicker/Calendar';

const ION_NUMBER_INPUT_IDENTIFIER = 'ion/NumberInput';

export const ION_COMPONENT_IDENTIFIERS = [
  ION_AVATAR_IDENTIFIER,
  ION_AVATAR_LABEL_IDENTIFIER,
  ION_AUTOCOMPLETE_IDENTIFIER,
  ION_BADGE_IDENTIFIER,
  ION_BANNER_IDENTIFIER,
  ION_BUTTON_IDENTIFIER,
  ION_CARD_IDENTIFIER,
  ION_CHECKBOX_IDENTIFIER,
  ION_COLLAPSIBLE_IDENTIFIER,
  ION_CALENDAR_IDENTIFIER,
  ION_DATEPICKER_IDENTIFIER,
  ION_DRAWER_IDENTIFIER,
  ION_DIVIDER_IDENTIFIER,
  ION_FILLED_ICON_IDENTIFIER,
  ION_HINT_IDENTIFIER,
  ION_ICON_BUTTON_IDENTIFIER,
  ION_INPUT_IDENTIFIER,
  ION_INPUTOTP_IDENTIFIER,
  ION_LABEL_IDENTIFIER,
  ION_LINK_BUTTON_IDENTIFIER,
  ION_MODAL_IDENTIFIER,
  ION_MULTISELECT_IDENTIFIER,
  ION_NAVLINK,
  ION_NUMBER_INPUT_IDENTIFIER,
  ION_PAGINATION_IDENTIFIER,
  ION_POPOVER_IDENTIFIER,
  ION_PROGRESS_IDENTIFIER,
  ION_RADIO_IDENTIFIER,
  ION_SELECT_IDENTIFIER,
  ION_SELECT_MENU_IDENTIFIER,
  ION_SIDEBAR_IDENTIFIER,
  ION_SIDE_NAVIGATION_FILLED_IDENTIFIER,
  ION_SIDE_NAVIGATION_DEFAULT_IDENTIFIER,
  ION_SIDE_NAVIGATION_ITEM_FILLED_IDENTIFIER,
  ION_SIDE_NAVIGATION_ITEM_IDENTIFIER,
  ION_SIMPLE_TABS_IDENTIFIER,
  ION_SLIDER_IDENTIFIER,
  ION_SWITCH_IDENTIFIER,
  ION_TABLE_IDENTIFIER,
  ION_TABS_IDENTIFIER,
  ION_TAG_IDENTIFIER,
  ION_TEXTAREA_IDENTIFIER,
] as const;

export type ION_COMPONENT = typeof ION_COMPONENT_IDENTIFIERS[number];

type BaseIonComponent<TIonComponent extends ION_COMPONENT> = {
  /** Identifier of the component */
  identifier: TIonComponent;
  /** Name of the component when referenced in code */
  name: string;
  /** Ion components that this component depends on */
  dependencies: ION_COMPONENT[];
  /** NPM packages that this component depends on */
  npmPackages: string[];
  /** Main ion component, used more than one figma node point to the same component */
  mainComponent?: ION_COMPONENT;
  /** Alternative component, used when more than one component point to the same figma node */
  alternativeComponent?: {
    identifier: Exclude<ION_COMPONENT, TIonComponent>;
    /** Component properties that determine if the component should be generated */
    propertyConditions: {
      // key equals value
      [key: string]: string | boolean;
    };
  };
  /** Filename to generate into */
  fileName?: string;
  /** Whether this component is a client component */
  isClientComponent?: boolean;
  /** Tailwind plugins that this component depends on */
  tailwindPlugins: string[];
  primitives?: ComponentPrimitiveType[];
  needsSafeList?: boolean;
};

const ION_DROPDOWN_MENU_ITEM_IDENTIFIER = 'ion/DropdownItem';
const ION_DROPDOWN_MENU_IDENTIFIER = 'ion/DropdownMenu';
export const ION_COMPONENT_HEADER_IDENTIFER = 'ion/ComponentHeader';
export const ION_COMPONENT_FOOTER_IDENTIFIER = 'ion/ComponentFooter';

export const componentPrimitives = [
  'autocomplete',
  'avatar',
  'alert',
  'badge',
  'button',
  'card',
  'checkbox',
  'collapsible',
  'datepicker',
  'drawer',
  'divider',
  'drawer',
  'hint',
  'input',
  'input-otp',
  'label',
  'modal',
  'multiselect',
  'number-input',
  'navlink',
  'pagination',
  'popover',
  'progress',
  'radio',
  'select',
  'select-menu',
  'select-menu-item',
  'slider',
  'switch',
  'table',
  'tabs',
  'tag',
  'textarea',
  'icon',
  'page',
  'unknown',
  'other',
  'img',
] as const;
export const ComponentPrimitiveSchema = z.enum(componentPrimitives);
export type ComponentPrimitiveType = typeof componentPrimitives[number];

type IonComponentMap = {
  [TIonComponent in ION_COMPONENT]: TIonComponent extends typeof ION_TABLE_IDENTIFIER
    ? BaseIonComponent<TIonComponent> & {
        header: string;
        row: string;
        headerCell: string;
        cell: string;
      }
    : TIonComponent extends typeof ION_MODAL_IDENTIFIER
    ? BaseIonComponent<TIonComponent> & {
        footer: typeof ION_COMPONENT_FOOTER_IDENTIFIER;
        header: typeof ION_COMPONENT_HEADER_IDENTIFER;
      }
    : TIonComponent extends typeof ION_DRAWER_IDENTIFIER
    ? BaseIonComponent<TIonComponent> & {
        footer: typeof ION_COMPONENT_FOOTER_IDENTIFIER;
        header: typeof ION_COMPONENT_HEADER_IDENTIFER;
      }
    : TIonComponent extends typeof ION_CARD_IDENTIFIER
    ? BaseIonComponent<TIonComponent> & {
        header: typeof ION_COMPONENT_HEADER_IDENTIFER;
      }
    : TIonComponent extends typeof ION_SELECT_IDENTIFIER | typeof ION_AUTOCOMPLETE_IDENTIFIER
    ? BaseIonComponent<TIonComponent> & {
        item: typeof ION_DROPDOWN_MENU_ITEM_IDENTIFIER;
      }
    : TIonComponent extends typeof ION_INPUTOTP_IDENTIFIER
    ? BaseIonComponent<TIonComponent> & {
        slot: `${typeof ION_INPUTOTP_IDENTIFIER}/Slot`;
        group: `${typeof ION_INPUTOTP_IDENTIFIER}/Group`;
      }
    : BaseIonComponent<TIonComponent>;
};

const CLASS_VARIANCE_AUTHORITY_PACKAGE = 'class-variance-authority';
const CLSX_PACKAGE = 'clsx';
const CMDK_PACKAGE = 'cmdk';
const REACT_PACKAGE = 'react';
const PHOSPHOR_ICONS_PACKAGE = '@phosphor-icons/react';
const TANSTACK_TABLE_PACKAGE = '@tanstack/react-table';
const TAILWIND_MERGE_PACKAGE = 'tailwind-merge';
const REACT_DAY_PICKER_PACKAGE = 'react-day-picker';
const TAILWINDCSS_ANIMATE = 'tailwindcss-animate';

const RADIX_UI_PACKAGES = {
  Collapsible: '@radix-ui/react-collapsible',
  Tabs: '@radix-ui/react-tabs',
  Switch: '@radix-ui/react-switch',
  Slider: '@radix-ui/react-slider',
  Select: '@radix-ui/react-select',
  RadioGroup: '@radix-ui/react-radio-group',
  Popover: '@radix-ui/react-popover',
  Progress: '@radix-ui/react-progress',
  NavigationMenuItem: '@radix-ui/react-navigation-menu',
  Modal: '@radix-ui/react-dialog',
  Avatar: '@radix-ui/react-avatar',
  Checkbox: '@radix-ui/react-checkbox',
  Separator: '@radix-ui/react-separator',
  Label: '@radix-ui/react-label',
  Slot: '@radix-ui/react-slot',
} as const;

const packages = {
  clsx: CLSX_PACKAGE,
  cva: CLASS_VARIANCE_AUTHORITY_PACKAGE,
  cmdk: CMDK_PACKAGE,
  react: REACT_PACKAGE,
  phosphor: PHOSPHOR_ICONS_PACKAGE,
  reactTable: TANSTACK_TABLE_PACKAGE,
  tailwindMerge: TAILWIND_MERGE_PACKAGE,
  reactDayPicker: REACT_DAY_PICKER_PACKAGE,
  radix: RADIX_UI_PACKAGES,
  tailwindcssAnimate: TAILWINDCSS_ANIMATE,
  vaul: 'vaul',
  reactNumberFormat: 'react-number-format',
} as const;

const plugins = {
  tailwindcssAnimate: 'tailwindcss-animate',
};

const ION_COMPONENTS: IonComponentMap = {
  [ION_NAVLINK]: {
    identifier: ION_NAVLINK,
    name: 'NavLink',
    dependencies: [],
    npmPackages: [packages.radix.Slot],
    primitives: ['navlink'],
    tailwindPlugins: [],
  },
  [ION_SELECT_MENU_IDENTIFIER]: {
    identifier: ION_SELECT_MENU_IDENTIFIER,
    name: 'SelectMenu',
    dependencies: [],
    npmPackages: [],
    primitives: ['select-menu'],
    tailwindPlugins: [],
  },
  [ION_AVATAR_IDENTIFIER]: {
    identifier: ION_AVATAR_IDENTIFIER,
    name: 'Avatar',
    dependencies: [],
    isClientComponent: true,
    npmPackages: [packages.clsx, packages.cva, packages.phosphor, packages.radix.Avatar],
    primitives: ['avatar'],
    tailwindPlugins: [],
  },
  [ION_AVATAR_LABEL_IDENTIFIER]: {
    identifier: ION_AVATAR_LABEL_IDENTIFIER,
    name: 'Avatar',
    mainComponent: ION_AVATAR_IDENTIFIER,
    dependencies: [],
    npmPackages: [packages.clsx, packages.cva, packages.phosphor, packages.radix.Avatar],
    tailwindPlugins: [],
  },
  [ION_AUTOCOMPLETE_IDENTIFIER]: {
    identifier: ION_AUTOCOMPLETE_IDENTIFIER,
    name: 'Autocomplete',
    item: ION_DROPDOWN_MENU_ITEM_IDENTIFIER,
    dependencies: [ION_LABEL_IDENTIFIER, ION_HINT_IDENTIFIER, ION_INPUT_IDENTIFIER, ION_POPOVER_IDENTIFIER],
    npmPackages: [packages.clsx, packages.tailwindMerge, packages.cva, packages.phosphor, packages.cmdk],
    tailwindPlugins: [plugins.tailwindcssAnimate],
    primitives: ['autocomplete'],
    isClientComponent: true,
  },
  [ION_BADGE_IDENTIFIER]: {
    identifier: ION_BADGE_IDENTIFIER,
    name: 'Badge',
    dependencies: [],
    npmPackages: [packages.clsx, packages.cva],
    primitives: ['badge'],
    tailwindPlugins: [],
  },
  [ION_BANNER_IDENTIFIER]: {
    identifier: ION_BANNER_IDENTIFIER,
    name: 'Banner',
    dependencies: [],
    npmPackages: [packages.clsx, packages.cva, packages.radix.Slot, packages.tailwindMerge],
    tailwindPlugins: [],
    primitives: ['alert'],
    needsSafeList: true,
  },
  [ION_BUTTON_IDENTIFIER]: {
    identifier: ION_BUTTON_IDENTIFIER,
    name: 'Button',
    dependencies: [],
    npmPackages: [packages.cva, packages.clsx, packages.tailwindMerge, packages.radix.Slot],
    tailwindPlugins: [],
    needsSafeList: true,
    primitives: ['button'],
  },
  [ION_CALENDAR_IDENTIFIER]: {
    identifier: ION_CALENDAR_IDENTIFIER,
    name: 'Calendar',
    dependencies: [ION_BUTTON_IDENTIFIER],
    npmPackages: [packages.reactDayPicker, packages.tailwindMerge, packages.phosphor],
    isClientComponent: true,
    tailwindPlugins: [],
  },
  [ION_CARD_IDENTIFIER]: {
    identifier: ION_CARD_IDENTIFIER,
    name: 'Card',
    dependencies: [],
    header: ION_COMPONENT_HEADER_IDENTIFER,
    npmPackages: [packages.clsx, packages.cva, packages.tailwindMerge],
    tailwindPlugins: [],
    primitives: ['card'],
  },
  [ION_CHECKBOX_IDENTIFIER]: {
    identifier: ION_CHECKBOX_IDENTIFIER,
    name: 'Checkbox',
    dependencies: [ION_LABEL_IDENTIFIER],
    npmPackages: [packages.radix.Checkbox, packages.phosphor, packages.clsx, packages.tailwindMerge],
    primitives: ['checkbox'],
    tailwindPlugins: [],
  },
  [ION_COLLAPSIBLE_IDENTIFIER]: {
    identifier: ION_COLLAPSIBLE_IDENTIFIER,
    name: 'Collapsible',
    dependencies: [],
    npmPackages: [packages.radix.Collapsible, packages.clsx, packages.tailwindcssAnimate],
    isClientComponent: true,
    primitives: ['collapsible'],
    tailwindPlugins: [plugins.tailwindcssAnimate],
  },
  [ION_DATEPICKER_IDENTIFIER]: {
    identifier: ION_DATEPICKER_IDENTIFIER,
    name: 'Datepicker',
    dependencies: [
      ION_POPOVER_IDENTIFIER,
      ION_CALENDAR_IDENTIFIER,
      ION_INPUT_IDENTIFIER,
      ION_BUTTON_IDENTIFIER,
      ION_LABEL_IDENTIFIER,
      ION_HINT_IDENTIFIER,
    ],
    npmPackages: [packages.reactDayPicker, packages.clsx, packages.tailwindMerge],
    primitives: ['datepicker'],
    isClientComponent: true,
    tailwindPlugins: [],
  },
  [ION_DIVIDER_IDENTIFIER]: {
    identifier: ION_DIVIDER_IDENTIFIER,
    name: 'Divider',
    dependencies: [],
    npmPackages: [packages.radix.Separator, packages.clsx],
    tailwindPlugins: [],
    primitives: ['divider'],
    isClientComponent: true,
  },
  [ION_DRAWER_IDENTIFIER]: {
    identifier: ION_DRAWER_IDENTIFIER,
    name: 'Drawer',
    dependencies: [ION_BUTTON_IDENTIFIER],
    footer: ION_COMPONENT_FOOTER_IDENTIFIER,
    header: ION_COMPONENT_HEADER_IDENTIFER,
    npmPackages: [packages.vaul, packages.clsx, packages.phosphor, packages.tailwindcssAnimate],
    isClientComponent: true,
    primitives: ['drawer'],
    tailwindPlugins: [plugins.tailwindcssAnimate],
  },
  [ION_FILLED_ICON_IDENTIFIER]: {
    identifier: ION_FILLED_ICON_IDENTIFIER,
    name: 'FilledIcon',
    dependencies: [],
    npmPackages: [packages.clsx, packages.cva, packages.phosphor, packages.radix.Avatar],
    tailwindPlugins: [],
  },
  [ION_HINT_IDENTIFIER]: {
    identifier: ION_HINT_IDENTIFIER,
    name: 'Hint',
    dependencies: [],
    npmPackages: [packages.clsx, packages.phosphor],
    primitives: ['hint'],
    tailwindPlugins: [],
  },
  [ION_ICON_BUTTON_IDENTIFIER]: {
    identifier: ION_ICON_BUTTON_IDENTIFIER,
    name: 'Button',
    mainComponent: ION_BUTTON_IDENTIFIER,
    npmPackages: [packages.cva, packages.clsx, packages.tailwindMerge],
    dependencies: [],
    tailwindPlugins: [],
    needsSafeList: true,
  },
  [ION_INPUT_IDENTIFIER]: {
    identifier: ION_INPUT_IDENTIFIER,
    name: 'Input',
    alternativeComponent: {
      identifier: ION_NUMBER_INPUT_IDENTIFIER,
      propertyConditions: {
        type: 'number',
      },
    },
    primitives: ['input'],
    dependencies: [ION_LABEL_IDENTIFIER, ION_HINT_IDENTIFIER],
    npmPackages: [packages.clsx, packages.tailwindMerge],
    tailwindPlugins: [],
  },
  [ION_INPUTOTP_IDENTIFIER]: {
    identifier: ION_INPUTOTP_IDENTIFIER,
    name: 'InputOTP',
    dependencies: [ION_LABEL_IDENTIFIER, ION_HINT_IDENTIFIER],
    npmPackages: [packages.clsx, packages.tailwindMerge],
    primitives: ['input-otp'],
    group: 'ion/InputOTP/Group',
    slot: 'ion/InputOTP/Slot',
    tailwindPlugins: [],
  },
  [ION_LABEL_IDENTIFIER]: {
    identifier: ION_LABEL_IDENTIFIER,
    name: 'Label',
    dependencies: [],
    primitives: ['label'],
    npmPackages: [packages.clsx, packages.radix.Label, packages.cva, packages.tailwindMerge],
    tailwindPlugins: [],
  },
  [ION_LINK_BUTTON_IDENTIFIER]: {
    identifier: ION_LINK_BUTTON_IDENTIFIER,
    name: 'Button',
    mainComponent: ION_BUTTON_IDENTIFIER,
    npmPackages: [packages.cva, packages.clsx, packages.tailwindMerge],
    dependencies: [],
    tailwindPlugins: [],
    needsSafeList: true,
  },
  [ION_MODAL_IDENTIFIER]: {
    identifier: ION_MODAL_IDENTIFIER,
    name: 'Modal',
    dependencies: [ION_BUTTON_IDENTIFIER],
    footer: ION_COMPONENT_FOOTER_IDENTIFIER,
    header: ION_COMPONENT_HEADER_IDENTIFER,
    primitives: ['modal'],
    npmPackages: [packages.radix.Modal, packages.clsx, packages.phosphor, packages.tailwindcssAnimate],
    isClientComponent: true,
    tailwindPlugins: [plugins.tailwindcssAnimate],
  },
  [ION_MULTISELECT_IDENTIFIER]: {
    identifier: ION_MULTISELECT_IDENTIFIER,
    name: 'MultiSelect',
    dependencies: [
      ION_LABEL_IDENTIFIER,
      ION_HINT_IDENTIFIER,
      ION_SELECT_IDENTIFIER,
      ION_POPOVER_IDENTIFIER,
      ION_DIVIDER_IDENTIFIER,
      ION_TAG_IDENTIFIER,
    ],
    primitives: ['multiselect'],
    npmPackages: [packages.clsx, packages.tailwindMerge, packages.cmdk],
    isClientComponent: true,
    tailwindPlugins: [],
  },
  [ION_NUMBER_INPUT_IDENTIFIER]: {
    identifier: ION_NUMBER_INPUT_IDENTIFIER,
    name: 'NumberInput',
    primitives: ['number-input'],
    dependencies: [ION_LABEL_IDENTIFIER, ION_HINT_IDENTIFIER, ION_INPUT_IDENTIFIER],
    npmPackages: [packages.clsx, packages.tailwindMerge, packages.reactNumberFormat],
    tailwindPlugins: [],
  },
  [ION_PAGINATION_IDENTIFIER]: {
    identifier: ION_PAGINATION_IDENTIFIER,
    name: 'Pagination',
    primitives: ['pagination'],
    dependencies: [ION_SELECT_IDENTIFIER],
    isClientComponent: true,
    npmPackages: [packages.clsx, packages.cva, packages.phosphor, packages.tailwindMerge, packages.tailwindcssAnimate],
    tailwindPlugins: [plugins.tailwindcssAnimate],
  },
  [ION_POPOVER_IDENTIFIER]: {
    identifier: ION_POPOVER_IDENTIFIER,
    name: 'Popover',
    primitives: ['popover'],
    dependencies: [],
    npmPackages: [packages.radix.Popover, packages.clsx, packages.tailwindMerge, packages.tailwindcssAnimate],
    isClientComponent: true,
    tailwindPlugins: [plugins.tailwindcssAnimate],
  },
  [ION_PROGRESS_IDENTIFIER]: {
    identifier: ION_PROGRESS_IDENTIFIER,
    name: 'Progress',
    primitives: ['progress'],
    dependencies: [],
    isClientComponent: true,
    npmPackages: [packages.radix.Progress, packages.clsx, packages.tailwindMerge],
    tailwindPlugins: [],
  },
  [ION_RADIO_IDENTIFIER]: {
    identifier: ION_RADIO_IDENTIFIER,
    name: 'Radio',
    primitives: ['radio'],
    dependencies: [ION_LABEL_IDENTIFIER],
    isClientComponent: true,
    npmPackages: [packages.radix.RadioGroup, packages.clsx],
    tailwindPlugins: [],
  },
  [ION_SELECT_IDENTIFIER]: {
    identifier: ION_SELECT_IDENTIFIER,
    name: 'Select',
    primitives: ['select', 'select-menu', 'select-menu-item'],
    dependencies: [ION_LABEL_IDENTIFIER, ION_HINT_IDENTIFIER],
    alternativeComponent: {
      identifier: ION_MULTISELECT_IDENTIFIER,
      propertyConditions: {
        type: 'multiple',
      },
    },
    item: ION_DROPDOWN_MENU_ITEM_IDENTIFIER,
    isClientComponent: true,
    npmPackages: [
      packages.radix.Select,
      packages.clsx,
      packages.tailwindMerge,
      packages.phosphor,
      packages.tailwindcssAnimate,
    ],
    tailwindPlugins: [plugins.tailwindcssAnimate],
  },
  [ION_SIDEBAR_IDENTIFIER]: {
    identifier: ION_SIDEBAR_IDENTIFIER,
    name: 'Sidebar',
    dependencies: [],
    npmPackages: [packages.clsx],
    tailwindPlugins: [],
  },
  [ION_SIDE_NAVIGATION_FILLED_IDENTIFIER]: {
    identifier: ION_SIDE_NAVIGATION_FILLED_IDENTIFIER,
    name: 'SideNavigation',
    dependencies: [],
    npmPackages: [packages.clsx],
    mainComponent: ION_SIDE_NAVIGATION_DEFAULT_IDENTIFIER,
    tailwindPlugins: [],
  },
  [ION_SIDE_NAVIGATION_DEFAULT_IDENTIFIER]: {
    identifier: ION_SIDE_NAVIGATION_DEFAULT_IDENTIFIER,
    name: 'SideNavigation',
    dependencies: [],
    npmPackages: [],
    tailwindPlugins: [],
  },
  [ION_SIDE_NAVIGATION_ITEM_IDENTIFIER]: {
    identifier: ION_SIDE_NAVIGATION_ITEM_IDENTIFIER,
    name: 'NavigationMenuLink',
    fileName: 'Navigation',
    dependencies: [],
    isClientComponent: true,
    npmPackages: [
      packages.clsx,
      packages.radix.NavigationMenuItem,
      packages.cva,
      packages.tailwindMerge,
      packages.radix.Slot,
    ],
    tailwindPlugins: [],
  },
  [ION_SIDE_NAVIGATION_ITEM_FILLED_IDENTIFIER]: {
    identifier: ION_SIDE_NAVIGATION_ITEM_FILLED_IDENTIFIER,
    name: 'NavigationMenuLink',
    fileName: 'Navigation',
    mainComponent: ION_SIDE_NAVIGATION_ITEM_IDENTIFIER,
    dependencies: [],
    isClientComponent: true,
    npmPackages: [
      packages.clsx,
      packages.radix.NavigationMenuItem,
      packages.cva,
      packages.tailwindMerge,
      packages.radix.Slot,
    ],
    tailwindPlugins: [],
  },
  [ION_SIMPLE_TABS_IDENTIFIER]: {
    identifier: ION_SIMPLE_TABS_IDENTIFIER,
    name: 'Tabs',
    dependencies: [],
    mainComponent: ION_TABS_IDENTIFIER,
    npmPackages: [packages.radix.Tabs, packages.clsx, packages.cva],
    isClientComponent: true,
    tailwindPlugins: [],
  },
  [ION_SLIDER_IDENTIFIER]: {
    identifier: ION_SLIDER_IDENTIFIER,
    name: 'Slider',
    dependencies: [],
    primitives: ['slider'],
    isClientComponent: true,
    npmPackages: [packages.radix.Slider, packages.clsx],
    tailwindPlugins: [],
  },
  [ION_SWITCH_IDENTIFIER]: {
    identifier: ION_SWITCH_IDENTIFIER,
    name: 'Switch',
    primitives: ['switch'],
    dependencies: [ION_LABEL_IDENTIFIER],
    isClientComponent: true,
    npmPackages: [packages.radix.Switch, packages.clsx],
    tailwindPlugins: [],
  },
  [ION_TABLE_IDENTIFIER]: {
    identifier: ION_TABLE_IDENTIFIER,
    name: 'Table',
    primitives: ['table'],
    dependencies: [],
    header: 'ion/TableHeader',
    headerCell: 'ion/TableHeaderCell',
    row: 'ion/TableRow',
    cell: 'ion/TableCell',
    isClientComponent: true,
    npmPackages: [packages.reactTable, packages.clsx, packages.tailwindMerge],
    tailwindPlugins: [],
  },
  [ION_TABS_IDENTIFIER]: {
    identifier: ION_TABS_IDENTIFIER,
    name: 'Tabs',
    primitives: ['tabs'],
    dependencies: [],
    npmPackages: [packages.radix.Tabs, packages.clsx, packages.cva],
    isClientComponent: true,
    tailwindPlugins: [],
  },
  [ION_TAG_IDENTIFIER]: {
    identifier: ION_TAG_IDENTIFIER,
    name: 'Tag',
    primitives: ['tag'],
    dependencies: [],
    isClientComponent: true,
    npmPackages: [packages.phosphor, packages.clsx, packages.phosphor],
    tailwindPlugins: [],
  },
  [ION_TEXTAREA_IDENTIFIER]: {
    identifier: ION_TEXTAREA_IDENTIFIER,
    name: 'Textarea',
    primitives: ['textarea'],
    dependencies: [ION_LABEL_IDENTIFIER, ION_HINT_IDENTIFIER],
    npmPackages: [packages.clsx, packages.tailwindMerge],
    tailwindPlugins: [],
  },
};

export const ION_GITHUB_PATHS: Record<ION_COMPONENT, string> = {
  [ION_PAGINATION_IDENTIFIER]: 'apps/design-system/src/components/Pagination.tsx',
  [ION_AVATAR_IDENTIFIER]: 'apps/design-system/src/components/Avatar.tsx',
  [ION_AVATAR_LABEL_IDENTIFIER]: 'apps/design-system/src/components/Avatar.tsx',
  [ION_AUTOCOMPLETE_IDENTIFIER]: 'apps/design-system/src/components/Autocomplete.tsx',
  [ION_BADGE_IDENTIFIER]: 'apps/design-system/src/components/Badge.tsx',
  [ION_BANNER_IDENTIFIER]: 'apps/design-system/src/components/Banner.tsx',
  [ION_BUTTON_IDENTIFIER]: 'apps/design-system/src/components/Button.tsx',
  [ION_CALENDAR_IDENTIFIER]: 'apps/design-system/src/components/Calendar.tsx',
  [ION_COLLAPSIBLE_IDENTIFIER]: 'apps/design-system/src/components/Collapsible.tsx',
  [ION_DATEPICKER_IDENTIFIER]: 'apps/design-system/src/components/Datepicker.tsx',
  [ION_DRAWER_IDENTIFIER]: 'apps/design-system/src/components/Drawer.tsx',
  [ION_CARD_IDENTIFIER]: 'apps/design-system/src/components/Card.tsx',
  [ION_CHECKBOX_IDENTIFIER]: 'apps/design-system/src/components/Checkbox.tsx',
  [ION_DIVIDER_IDENTIFIER]: 'apps/design-system/src/components/Divider.tsx',
  [ION_FILLED_ICON_IDENTIFIER]: 'apps/design-system/src/components/FilledIcon.tsx',
  [ION_HINT_IDENTIFIER]: 'apps/design-system/src/components/Hint.tsx',
  [ION_ICON_BUTTON_IDENTIFIER]: 'apps/design-system/src/components/Button.tsx',
  [ION_INPUT_IDENTIFIER]: 'apps/design-system/src/components/Input.tsx',
  [ION_INPUTOTP_IDENTIFIER]: 'apps/design-system/src/components/InputOTP.tsx',
  [ION_LABEL_IDENTIFIER]: 'apps/design-system/src/components/Label.tsx',
  [ION_LINK_BUTTON_IDENTIFIER]: 'apps/design-system/src/components/Button.tsx',
  [ION_MULTISELECT_IDENTIFIER]: 'apps/design-system/src/components/MultiSelect.tsx',
  [ION_NAVLINK]: 'apps/design-system/src/components/NavLink.tsx',
  [ION_MODAL_IDENTIFIER]: 'apps/design-system/src/components/Modal.tsx',
  [ION_NUMBER_INPUT_IDENTIFIER]: 'apps/design-system/src/components/NumberInput.tsx',
  [ION_POPOVER_IDENTIFIER]: 'apps/design-system/src/components/Popover.tsx',
  [ION_PROGRESS_IDENTIFIER]: 'apps/design-system/src/components/Progress.tsx',
  [ION_RADIO_IDENTIFIER]: 'apps/design-system/src/components/Radio.tsx',
  [ION_SELECT_IDENTIFIER]: 'apps/design-system/src/components/Select.tsx',
  [ION_SELECT_MENU_IDENTIFIER]: 'apps/design-system/src/components/SelectMenu.tsx',
  [ION_SIDEBAR_IDENTIFIER]: 'apps/design-system/src/components/Sidebar.tsx',
  [ION_SIDE_NAVIGATION_ITEM_IDENTIFIER]: 'apps/design-system/src/components/Navigation.tsx',
  [ION_SIDE_NAVIGATION_ITEM_FILLED_IDENTIFIER]: 'apps/design-system/src/components/Navigation.tsx',
  // this should never be reached
  [ION_SIDE_NAVIGATION_DEFAULT_IDENTIFIER]: '-',
  [ION_SIDE_NAVIGATION_FILLED_IDENTIFIER]: '-',
  [ION_SIMPLE_TABS_IDENTIFIER]: 'apps/design-system/src/components/Tabs.tsx',
  [ION_SLIDER_IDENTIFIER]: 'apps/design-system/src/components/Slider.tsx',
  [ION_SWITCH_IDENTIFIER]: 'apps/design-system/src/components/Switch.tsx',
  [ION_TABLE_IDENTIFIER]: 'apps/design-system/src/components/Table.tsx',
  [ION_TABS_IDENTIFIER]: 'apps/design-system/src/components/Tabs.tsx',
  [ION_TAG_IDENTIFIER]: 'apps/design-system/src/components/Tag.tsx',
  [ION_TEXTAREA_IDENTIFIER]: 'apps/design-system/src/components/Textarea.tsx',
} as const;

export type ComponentPrimitiveWithoutTemplate = Extract<
  ComponentPrimitiveType,
  'unknown' | 'page' | 'sidenavigation' | 'icon' | 'other' | 'img' | 'navigation'
>;

export const primitivesWithoutTemplate: ComponentPrimitiveWithoutTemplate[] = [
  'unknown',
  'page',
  'other',
  'icon',
  'img',
];

export const isPrimitiveWithoutTemplate = (
  primitive: ComponentPrimitiveType
): primitive is ComponentPrimitiveWithoutTemplate =>
  primitivesWithoutTemplate.includes(primitive as ComponentPrimitiveWithoutTemplate);

componentPrimitives.map((primitive) => {
  if (primitivesWithoutTemplate.includes(primitive as ComponentPrimitiveWithoutTemplate)) return true;

  const primitiveExists = Object.values(ION_COMPONENTS).find((component) => component.primitives?.includes(primitive));

  if (!primitiveExists) {
    throw new Error(`No ion component found for primitive ${primitive}`);
  }
});

export const FREE_GENERATIONS_FROM_PROMPT = 10;

export const ONBOARDING_LINK = 'https://www.figma.com/file/gEP8BXRhEUxCBGcLorxfgS/ion-onboarding?node-id=2%3A3783';

export const GENERATIONS_UNTIL_SUBSCRIPTION_IS_REQUIRED = 3;

export {
  createEnv,
  NODE_ENV,
  ION_COMPONENTS,
  ION_AVATAR_IDENTIFIER,
  ION_AVATAR_LABEL_IDENTIFIER,
  ION_AUTOCOMPLETE_IDENTIFIER,
  ION_BADGE_IDENTIFIER,
  ION_BANNER_IDENTIFIER,
  ION_BUTTON_IDENTIFIER,
  ION_CARD_IDENTIFIER,
  ION_CALENDAR_IDENTIFIER,
  ION_CHECKBOX_IDENTIFIER,
  ION_COLLAPSIBLE_IDENTIFIER,
  ION_DATEPICKER_IDENTIFIER,
  ION_DIVIDER_IDENTIFIER,
  ION_DRAWER_IDENTIFIER,
  ION_DROPDOWN_MENU_ITEM_IDENTIFIER,
  ION_DROPDOWN_MENU_IDENTIFIER,
  ION_FILLED_ICON_IDENTIFIER,
  ION_HINT_IDENTIFIER,
  ION_ICON_BUTTON_IDENTIFIER,
  ION_INPUT_IDENTIFIER,
  ION_INPUTOTP_IDENTIFIER,
  ION_LABEL_IDENTIFIER,
  ION_LINK_BUTTON_IDENTIFIER,
  ION_MULTISELECT_IDENTIFIER,
  ION_MODAL_IDENTIFIER,
  ION_NUMBER_INPUT_IDENTIFIER,
  ION_PAGINATION_IDENTIFIER,
  ION_PROGRESS_IDENTIFIER,
  ION_POPOVER_IDENTIFIER,
  ION_RADIO_IDENTIFIER,
  ION_SELECT_IDENTIFIER,
  ION_SELECT_MENU_IDENTIFIER,
  ION_SIMPLE_TABS_IDENTIFIER,
  ION_SLIDER_IDENTIFIER,
  ION_SWITCH_IDENTIFIER,
  ION_TABLE_IDENTIFIER,
  ION_TABS_IDENTIFIER,
  ION_TAG_IDENTIFIER,
  ION_TEXTAREA_IDENTIFIER,
  ION_SIDEBAR_IDENTIFIER,
  ION_SIDE_NAVIGATION_DEFAULT_IDENTIFIER,
  ION_SIDE_NAVIGATION_FILLED_IDENTIFIER,
  ION_SIDE_NAVIGATION_ITEM_IDENTIFIER,
  ION_SIDE_NAVIGATION_ITEM_FILLED_IDENTIFIER,
};
