import { DittofeedSdk } from '@dittofeed/sdk-node';

import { env } from './env';

export async function identifyDitto(user: {
  id: string;
  email?: string | null | undefined;
  name?: string | null | undefined;
  createdAt?: Date | null | undefined;
}) {
  await DittofeedSdk.init({
    writeKey: env.NEXT_PUBLIC_DITTOFEED_WRITE_KEY,
  });

  DittofeedSdk.identify({
    userId: user.id,
    traits: {
      email: user.email,
      name: user.name,
      firstName: user.name?.split(' ')[0] ?? user.name,
      createdAt: user.createdAt,
    },
  });

  await DittofeedSdk.flush();
}

export async function trackEventDitto(event: string, user: { id: string }, properties?: object) {
  await DittofeedSdk.init({
    writeKey: env.NEXT_PUBLIC_DITTOFEED_WRITE_KEY,
  });

  DittofeedSdk.track({
    userId: user.id,
    event: event,
    properties,
  });
  await DittofeedSdk.flush();
}

export enum DittoEvents {
  CREATE_GENERATION = 'CREATE_GENERATION',
}
